import requests from "@/utils/requests";

//获取转向练习类型
export const reqGetQuestionType = (data:any) => {
    return requests({
        url:'/api/question/getUserQuestionInfo',
        method:'post',
        headers:{'content-type':'application/json'},
        data
    })
}
//获取模拟考试题目
export const reqGetExamList = (data:any) => {
    return requests({
        url:'/api/question/getQuestionExam',
        method:'post',
        headers:{'content-type':'application/json'},
        data
    })
}
//交卷接口
export const getFinalScore = (data:any) => {
    return requests({
        url:'/api/question/getFinalScore',
        method:'post',
        headers:{'content-type':'application/json'},
        data
    })
}
//获取排行榜
export const getRankingList = () => {
    return requests({
        url:'/api/question/getRankingList',
        method:'post',
        headers:{'content-type':'application/json'},
    })
}
//添加收藏
export const addRecordOrError = (data:any) => {
    return requests({
        url:'/api/question/addRecordOrError',
        method:'post',
        headers:{'content-type':'application/json'},
        data
    })
}
//删除收藏
export const deleteRecord = (data:any) => {
    return requests({
        url:'/api/question/deleteRecord',
        method:'post',
        headers:{'content-type':'application/json'},
        data
    })
}
//获取收藏、错题记录
export const getQuestionErrorOrRecord = (data:any) => {
    return requests({
        url:'/api/question/getQuestionErrorOrRecord',
        method:'post',
        headers:{'content-type':'application/json'},
        data
    })
}
//后台添加题目
export const reqAddQuestion = (data:any) => {
    return requests({
        url:'/api/question/addQuestion',
        method:'post',
        headers:{'content-type':'application/json'},
        data
    })
}