import React, {useEffect, useRef, useState} from "react";
import styles from './index.module.css'
import {Button, Radio, Form, Input, message, Modal, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import RichText from "@/pages/f/commonCompnents/RichText";
import {reqUpdateArticleList} from "@/api/f/blog";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
const QuestionList=()=>{
    const [examList,setExamList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(true);

    // wer=(e:any)=>{
    //     setAddQuestion((prevState:any)=>({
    //         ...prevState,
    //         answer: e.target.value
    //     }))
    // }
    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    //
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    //
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };
    // useEffect(() => {
    //
    // }, []);
    // const richTextRef: any = useRef<any>(null);
    // const getEditorContent = () => {
    //     if (richTextRef.current) {
    //         const editorContent = richTextRef.current.getEditorContent();
    //         setAddQuestion((prevState: any) => ({
    //             ...prevState,
    //             content: editorContent,
    //         }));
    //     }
    // };
    // const handleSubmit = () => {
    //
    //     if (richTextRef.current) {
    //         const editorContent = richTextRef.current.getEditorContent();
    //         console.log("值式",editorContent)
    //     }
    // };
    // const formItemLayout = {
    //     // labelCol: {
    //     //     xs: { span: 24 },
    //     //     sm: { span: 4 },
    //     // },
    //     // wrapperCol: {
    //     //     xs: { span: 24 },
    //     //     sm: { span: 20 },
    //     // },
    // };
    //
    // const formItemLayoutWithOutLabel = {
    //     wrapperCol: {
    //         xs: { span: 24, offset: 0 },
    //         sm: { span: 20, offset: 4 },
    //     },
    // };
    // const handleAddField = (fields: any[], add: Function) => {
    //     const newField = { name: `field_${fields.length}`, value: '' };
    //     setAddQuestion((prevState: any) => ({
    //         ...prevState,
    //         dynamicFields: [...(prevState.dynamicFields || []), newField],
    //     }));
    //     add(newField);
    // };
    // const opotinLabel:any={
    //     0:"选项A：",
    //     1:"选项B：",
    //     2:"选项C：",
    //     3:"选项D：",
    //     4:"选项E：",
    //     5:"选项F：",
    //     6:"选项G："
    // }
    return <div className={styles['mainBox']}>
        <div>

        </div>



    </div>
}
export default QuestionList