//此时服务器连接的ip
import {bgUrl} from "@/utils/themeTools";

export const serverIp = 'https://www.lblssl.cn'

//背景图
//PC端
// export  const bgImgList=['https://img.lblssl.cn/other/banner3.jpg','https://img.lblssl.cn/mainBg/mainBg4.jpg','https://img.lblssl.cn/mainBg/mainBg3.jpg','https://img.lblssl.cn/mainBg/mainBg.jpg']
export  const bgImgList=['https://img.lblssl.cn/other/banner3.jpg','https://img.lblssl.cn/other/mainBg5.jpg','https://img.lblssl.cn/mainBg/mainBg3.jpg','https://img.lblssl.cn/mainBg/mainBg.jpg']
export const bgImg =bgUrl(bgImgList)
// console.log("背景图是:",bgImg)
// https://img.lblssl.cn/other/banner3.jpg  https://img.lblssl.cn/mainBg/mainBg.jpg
// export const bgImg ="https://img.lblssl.cn/mainBg/mainBg3.jpg"
//移动端
export const mBgImg ="https://img.lblssl.cn/other/mbananer3.jpg"

//个人信息
//个人头像
export const personalPic ="https://img.lblssl.cn/user-img/qiya.jpg"
//ssl头像
export const sslImg ='https://img.lblssl.cn/user-img/sslAvatar.jpg'
//网站封面
export const coverUrl = "https://img.lblssl.cn/friends/77e7b111f5da44669dfe1423b7f18111.png"
//域名
export const myUrl = "https://www.lblssl.cn"
//webhook机器人
//三人组
export const bzlWebhook = 'https://qyapi.weixin.qq.com/cgi-bin/webhook/send?key=1d283c8e-73ad-47e7-9845-746bb2d0ae8a'
//ssl
export const sslWebhook = 'https://qyapi.weixin.qq.com/cgi-bin/webhook/send?key=ec63422f-64f5-4e8a-95f7-8c8f4d7a476f'
