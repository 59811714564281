import {Outlet} from "react-router-dom";

const QuestionMangement=()=>{
    return (
        <div>
            <Outlet></Outlet>
        </div>
    )
}
export default QuestionMangement
