import {requests} from "../../utils";


//获取经验值
export  const reqLvInfo=(data:any)=>{
    return requests({
        url:'/api/lv/getLvInfo',
        method:"post",
        headers:{
            'Content-Type':'application/json'
        },
        data
    })
}
//添加经验
export  const reqAddLvExp=(data:any)=>{
    return requests({
        url:'/api/lv/addUserExp',
        method:"post",
        headers:{
            'Content-Type':'application/json'
        },
        data
    })
}