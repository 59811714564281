import {Button, Form, Input, Radio, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import RichText from "@/pages/f/commonCompnents/RichText";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useRef, useState} from "react";
import styles from './index.module.css'
import {reqAddQuestion, reqGetQuestionType} from "@/api/f/question";

const AddQuestion = () => {
    const richTextRef: any = useRef<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [form] = Form.useForm(); // 获取表单实例
    const userId = localStorage.getItem("userId")
    let [questionOption, setQuestionOption] :any= useState([])
    useEffect(() => {
        initFormData()
    }, []);
    const initFormData = () => {
        reqGetQuestionType({userId}).then(res => {
            if (res.status) {
                let resArr = []
                for (let i = 0; i < res.data.questionTypeList.length; i++) {
                    let item = {
                        label: res.data.questionTypeList[i].name,
                        value: res.data.questionTypeList[i].value
                    }
                    resArr.push(item)
                }
                setQuestionOption(resArr)
            }
        })
    }
    let [addQuestion, setAddQuestion] = useState({
        title: "", // 题目
        content: "", // 题目主体（一般包含代码块）（可选）
        answer: "", // 题目答案
        type: "", // 题目类型
        difficulty: "easy", // 题目难度
        questionType: "1", // 单选还是多选
        options: [], // 选项
    });

    // 处理选项变化
    const handleOptionsChange = () => {
        const values = form.getFieldValue("options"); // 获取 Form.List 选项的值
        console.log("@@", values)

        setAddQuestion((prevState) => ({
            ...prevState,
            options: values.map((item: any) => item != undefined ? item : ""), // 只存选项值
        }));


    };

    const handleSubmit = () => {
        if (richTextRef.current) {
            const editorContent = richTextRef.current.getEditorContent();
            form.validateFields().then(() => {
                const updatedQuestion = {
                    ...addQuestion,
                    content: editorContent,
                };
                reqAddQuestion(updatedQuestion).then(res=>{
                    if (res){

                    }
                })
                console.log(updatedQuestion);
            }).catch(err => {
                console.log("表单验证失败", err);
            });
        }
    };

    return (
        <div className={styles['mainBox']}>
            <Form
                form={form}
                name="basic"
                labelCol={{span: 2}}
                wrapperCol={{span: 22}}
                initialValues={{remember: true}}
                autoComplete="off"
            >
                <Form.Item
                    label="题目名称"
                    name="title"
                    rules={[{required: true, message: "请填写你的题目名称~"}]}
                >
                    <TextArea
                        value={addQuestion.title}
                        onChange={(e) =>
                            setAddQuestion((prevState) => ({...prevState, title: e.target.value}))
                        }
                        placeholder="请输入题目名称"
                        autoSize={{minRows: 2, maxRows: 3}}
                    />
                </Form.Item>

                <Form.Item
                    label="题目主体"
                    name="content"

                >
                    <RichText data={addQuestion.content} ref={richTextRef}/>
                </Form.Item>

                <Form.Item
                    label="题目范围"
                    name="type"
                    rules={[{required: true, message: "请选择题目范围!"}]}
                >
                    <Select
                        onChange={(e) => setAddQuestion((prevState) => ({...prevState, type: e}))}
                        options={questionOption}
                    />
                </Form.Item>

                {/* 选项管理 */}
                <Form.List name="options">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item label={`选项 ${String.fromCharCode(65 + index)}`} key={field.key}>
                                    <Form.Item
                                        {...field}
                                        validateTrigger={["onChange", "onBlur"]}
                                        rules={[
                                            {required: true, message: "请输入选项内容!"},
                                        ]}
                                        noStyle
                                    >
                                        <Input
                                            placeholder="请输入选项内容"
                                            onChange={handleOptionsChange} // 监听变化
                                            style={{width: "60%"}}
                                        />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => {
                                                remove(field.name);
                                                handleOptionsChange(); // 更新 state
                                            }}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add();
                                        handleOptionsChange(); // 监听添加操作
                                    }}
                                    style={{width: "60%"}}
                                    icon={<PlusOutlined/>}
                                >
                                    添加选项
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item label="题目答案">
                    <Input
                        value={addQuestion.answer}
                        onChange={(e) =>
                            setAddQuestion((prevState) => ({...prevState, answer: e.target.value}))
                        }
                    />
                </Form.Item>

                <Form.Item label="题目类型">
                    <Radio.Group
                        value={addQuestion.questionType}
                        onChange={(e) =>
                            setAddQuestion((prevState) => ({
                                ...prevState,
                                questionType: e.target.value,
                            }))
                        }
                        options={[
                            {value: "1", label: "单选"},
                            {value: "2", label: "多选"},
                        ]}
                    />
                </Form.Item>

                <Form.Item label="难度">
                    <Radio.Group
                        onChange={(e) =>
                            setAddQuestion((prevState) => ({
                                ...prevState,
                                difficulty: e.target.value,
                            }))
                        }
                        value={addQuestion.difficulty}
                        options={[
                            {value: "easy", label: "简单"},
                            {value: "medium", label: "中等"},
                            {value: "hard", label: "困难"},
                        ]}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={handleSubmit}>
                        添加
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddQuestion;
