//获取时间字符串
export const timeAgo=(dateString:string )=> {
    const date:any = new Date(dateString);
    const now:any = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
        return "刚刚";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes}分钟前`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return `${diffInHours}小时前`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) {
        return `${diffInDays}天前`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
        return `${diffInMonths}个月前`;
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    return `${diffInYears}年前`;
}

//分割url
export const  splitURL=(url:string)=> {
    // 使用正则表达式来分割URL
    const regex = /^(https?:\/\/[^\/]+\/)(.*\/)?([^\/]+)$/;
    const matches = url.match(regex);

    if (matches) {
        const prefix = matches[1];  // 前缀
        const path = matches[2] || '';  // 路径（可选）
        const filename = matches[3];  // 文件名

        return {
            prefix,
            path,
            filename
        };
    } else {
        throw new Error("URL格式不正确");
    }
}

//计算rate星星
export const computedStar = (value: number) => {
let difficulty =''
    if (value <= 2) {
        difficulty = 'easy';
    } else if (value > 2 && value <= 4) {
        difficulty = 'middle';
    } else if (value > 4) {
        difficulty = 'hard';
    }
    return  difficulty ;
};

/**
 * @param {number[]} coins
 * @param {number} amount
 * @return {number}
 */
export const coinChange = (coins: number[], amount: number): number[] => {
    // dp数组存储达到每个金额所需的最小硬币数
    const dp = new Array(amount + 1).fill(Infinity);
    // coinUsed数组存储达到每个金额最后使用的硬币
    const coinUsed = new Array(amount + 1).fill(-1);

    dp[0] =0 ; // 凑出总额0需要0枚硬币

    for (const coin of coins) {
        for (let j = coin; j <= amount; j++) {
            if (dp[j - coin] + 1 < dp[j]) {
                dp[j] = dp[j - coin] + 1;
                coinUsed[j] = coin; // 记录最后使用的硬币
            }
        }
    }

    // 如果没有解，返回空数组
    if (dp[amount] === Infinity) {
        return [];
    }

    // 回溯找出使用的硬币
    const result: number[] = [];
    let remaining = amount;
    while (remaining > 0) {
        const coin = coinUsed[remaining];
        result.push(coin);
        remaining -= coin;
    }

    return result;
};
