import React, {useEffect, useRef, useState} from 'react';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import {CSSTransition, SwitchTransition, TransitionGroup} from 'react-transition-group';
import routes from '../router';
import ScrollToTop from "@/components/ScrollToTop";
import commonStyles from './f/css/common.module.css';
import LoadingPage from "@/components/loadingPage";
import {useDispatch} from "react-redux";
import {setIsMobileAction} from "@/redux/actions/commonAction";
import FullScreenLoading from "@/components/FullScreenLoading";
import {message} from "antd";
import Lantern from "@/components/lantern"; // 引入模块化的CSS

// 递归解析路由组件
const generateRoutes = (routes: any) => {
    return routes.map((route: any, index: any) => (
        <Route key={route.path} path={route.path} element={<route.component/>}>
            {route.children && route.children.length > 0 && generateRoutes(route.children)}
        </Route>
    ));
};

const LayoutMain = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const distpatch = useDispatch()
    // console.log("page/index.tsx啦啦啦@@@@@", location.pathname);
    const [loading, setLoading] = useState(true);
    const timerRef = useRef<number | null>(null);
    const nodeRef = useRef(null); // 添加这个引用
// 递归查找当前路径的路由
    const findRouteByPath = (routes: any[], path: string): any => {
        for (const route of routes) {
            if (route.path === path) {
                return route;
            }
            if (route.children) {
                const foundRoute = findRouteByPath(route.children, path);
                if (foundRoute) {
                    return foundRoute;
                }
            }
        }
        return null;
    };

    useEffect(() => {
        if (location.pathname !== "/") {
            distpatch(setIsMobileAction(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)))
            const currentRoute = findRouteByPath(routes, location.pathname.split('/').pop() as string);

            if (currentRoute) {
                document.title = currentRoute.title || "木瓜一块八"; // 如果没有 title 则使用默认标题
            }
            timerRef.current = window.setTimeout(() => {
                setLoading(false);
            }, 300);
            return () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
            };
        } else {
            navigate('/fMainPage/fHomePage');
            return;
        }
    }, [location.pathname]);

    return (
        <>
            {
                location.pathname!="/"?<div>
                    <ScrollToTop/>
                    <div className={`${commonStyles['init-route-style']} ${loading ? commonStyles['show'] : ''}`}>
                        {/*<LoadingPage/>*/}
                        {/*<FullScreenLoading></FullScreenLoading>*/}
                    </div>
                    {/*<Lantern text="新年大吉"></Lantern>*/}
                    <TransitionGroup component={null}>
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={location.key}
                                classNames={{
                                    enter: commonStyles['fade-enter'],
                                    enterActive: commonStyles['fade-enter-active'],
                                    exit: commonStyles['fade-exit'],
                                    exitActive: commonStyles['fade-exit-active']
                                }}
                                timeout={300}
                                nodeRef={nodeRef} // 添加这个引用
                            >
                                <div ref={nodeRef} className={commonStyles['route-section']}>
                                    <Routes location={location}>
                                        {generateRoutes(routes)}
                                    </Routes>
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                    </TransitionGroup>
                </div>:''

            }
        </>
    );
};

export default LayoutMain;
