import styles from './index.module.css'
import React, {useEffect, useRef, useState} from "react";
import AnswerItem from "@/pages/f/faceClassic/answerItem";
import {Badge, Button, Drawer, Form, Input, message, Modal, Radio, Select, Switch, Tag} from 'antd';
import {
    DoubleRightOutlined,
    FormOutlined,
    LeftOutlined,
    LoadingOutlined,
    RightOutlined,
    SettingOutlined
} from '@ant-design/icons';
import {reqGetFaceClassicList, reqgetFaceClassicTypeList, reqUpdateFaceClassic} from "@/api/f/faceClassic";
import {ReactComponent as CenterIcon} from "./img/center.svg";
import {ReactComponent as LeftIcon} from "./img/left.svg";
import {ReactComponent as RightIcon} from "./img/right.svg";
import {ReactComponent as VueIcon} from "./img/Vue.svg";
import {ReactComponent as ReactIcon} from "./img/react.svg";
import {ReactComponent as LlqIcon} from "./img/llq.svg";
import {ReactComponent as ProjectIcon} from "./img/project.svg";
import {ReactComponent as QuestionIcon} from "./img/q.svg";
import {ReactComponent as VipIcon} from "./img/VIP.svg";
import {ReactComponent as TsIcon} from "./img/ts.svg";
import {ReactComponent as HandIcon} from "./img/hand.svg";
import {ReactComponent as ToolIcon} from "./img/tool.svg";
import {ReactComponent as YhIcon} from "./img/yh.svg";
import {ReactComponent as Gitcon} from "./img/git.svg";
import {ReactComponent as Linuxcon} from "./img/linux.svg";
import {ReactComponent as Html5con} from "./img/HTML5.svg";
import {ReactComponent as Jscon} from "./img/JavaScript.svg";
import RichText from "@/pages/f/commonCompnents/RichText";
import BtnLoading from "@/components/btnLoading";
import {useNavigate} from "react-router-dom";
import {themeColor} from "@/settings/theme";

const FaceClassic = () => {
    const userId = JSON.parse(localStorage.getItem('userInfo') as string).userId
    const [showList, setShowList]: any = useState([])
    const [type, setType] = useState('1')
    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [isCheck, setIscheck] = useState('0')
    const [currentId, setCurrentId] = useState('')
    const [tempShowList, setTempShowList]: any = useState([])
    const [loadingBtn, setLoadingBtn] = useState(true)
    const [loadingMore, setLoadingMore] = useState(true)
    const onChange = (e: any) => {
        if (showMore) {
            setShowMore(false)
        }
        setLoadingBtn(true)
        setShowList([])
        setType(e.target.value)
        setCurrent(1)
        console.log(`radio checked:${e.target.value}`);
        let ploay = {
            type: e.target.value,
            current: 1,
            pageSize,
            isCheck,
            userId,
            b_orf: 'f'
        }
        initData(ploay)

    };
    const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true)
    const onSearch: any = (value: any, _e: any, info: any) => {
        message.success("搜索按钮被触发了~" + value)
    }
    const [currentProblomObj, setCurrentProblomObj] = useState({
        id: '',
        title: '',
        type: '',
        answer: '',
        updateTime: '',
        rate: '',
        isCheck: '',
        userId: ''

    })
    const [colnumNum, setColnum] = useState(2)
    const [contentLWidth, setContentWidth] = useState(50)
    const configColnum = (colnums: any) => {
        if (colnums === 2) {
            setColnum(2)
            setContentWidth(50)
        } else if (colnums === 3) {
            setColnum(3)
            setContentWidth(60)
        } else {
            setColnum(1)
            setContentWidth(20)
        }


    }
    const [typeList, setTypeList] = useState([])
    const [selectTypeList, setSelectTypeList]: any = useState([])



    useEffect(() => {
        reqgetFaceClassicTypeList({userId, b_orf: 'f'}).then(res => {
            if (res.status) {
                setTypeList(res.data)
                let resSelect = []
                for (let i = 0; i < res.data.length; i++) {
                    let item = {
                        value: res.data[i].id,
                        label: res.data[i].value,

                    }
                    resSelect.push(item)

                }
                setSelectTypeList(resSelect)
                let ploay = {
                    type: res.data.length > 0 ? res.data[0].id : '1',
                    current,
                    pageSize,
                    isCheck,
                    userId,
                    b_orf: 'f'
                }
                initData(ploay)
            }
        })

    }, []);
    const initData = (ploay: any) => {
        reqGetFaceClassicList(ploay).then(res => {
            setLoadingMore(false)
            setLoadingBtn(false)
            if (res.status) {

                if (res.data.length < pageSize) {
                    setShowLoadMoreBtn(false)
                } else if (!showLoadMoreBtn) {
                    setShowLoadMoreBtn(true)
                }
                if (ploay.current === 1) {
                    setTempShowList(res.data)
                    setShowList(res.data); // 如果是第一页，直接设置数据
                    let hasData = res.data.length > 0
                    if (hasData) {
                        setCurrentId(res.data[0].id)
                        setCurrentProblomObj((prevState: any) => {
                            return {
                                ...prevState,
                                title: res.data[0].title,
                                answer: res.data[0].answer,
                                updateTime: res.data[0].updateTime,
                                isCheck: res.data[0].isCheck,
                                id: res.data[0].id,
                                type: res.data[0].type,
                                rate: res.data[0].rate,
                                userId: res.data[0].userId,
                            }
                        })
                    }

                } else {
                    // setShowList((prevShowList:any) => [...prevShowList, ...res.data]); // 如果是下一页，拼接数据
                    setShowList([...showList, ...res.data]); // 如果是下一页，拼接数据
                    setTempShowList([...showList, ...res.data])

                }


            }
        })
    }
    const changeAnswer = (item: any) => {
        if (showMore) {
            setShowMore(false)
        }
        setCurrentId(item.id)
        setCurrentProblomObj((prevState: any) => {
            return {
                ...prevState,
                title: item.title,
                answer: item.answer,
                updateTime: item.updateTime,
                isCheck: item.isCheck,
                id: item.id,
                type: item.type,
                rate: item.rate,
                userId: item.userId,
            }
        })
    }
    const TagContent: Record<string, React.ReactNode> = {
        // -1单选
        '1': <Tag icon={<Html5con/>} color="yellow">Html/Css</Tag>,
        '2': <Tag icon={<Jscon/>} color="pink">JavaScript</Tag>,
        '3': <Tag icon={<TsIcon/>} color="error">TypeScript</Tag>,
        '4': <Tag icon={<VueIcon/>} color="green">Vue2</Tag>,
        '5': <Tag icon={<VueIcon/>} color="green">Vue3</Tag>,
        '6': <Tag icon={<ReactIcon/>} color="cyan">React</Tag>,
        '7': <Tag icon={<LlqIcon/>} color="blue">浏览器</Tag>,
        '8': <Tag icon={<ProjectIcon/>} color="magenta">项目</Tag>,
    };
    const newTagContent: Record<string, React.ReactNode> = {
        '1': <Html5con/>,
        '2':<Jscon/>,
        '3': <TsIcon/>,
        '4': <VueIcon/>,
        '5': <VueIcon/>,
        '6': <ReactIcon/>,
        '7': <LlqIcon/>,
        '8': <ProjectIcon/>,
        '6f55bb15-f120-4b6d-9c0f-f8e8801aec63': <HandIcon/>,
        '35e2e9f4-2ad9-48c5-9676-88f3655508f3': <ToolIcon/>,
        '147a80f5-3045-4d11-a96a-e66167f2d483': <YhIcon/>,
        '7ee6550d-21c2-4feb-99b2-1e784c6e0f51': <Gitcon/>,
        'ac6e615d-4d08-4a2e-b4a2-2684f6956c81': <Linuxcon/>,
    };
    const RateBox = (rate: string) => {
        if (rate === '1') {
            return <Tag style={{height:20}} color="green">低</Tag>
        } else if (rate === '2') {
            return <Tag  style={{height:20}} color="blue">中</Tag>
        } else {
            return <Tag style={{height:20}}  color="red">高</Tag>
        }
    }

    const loadMoreBtn = () => {
        if (showMore) {
            setShowMore(false)
        }
        setLoadingMore(true)
        setCurrent((prevState: any) => {
            let ploay = {
                type,
                current: prevState + 1, // 这里使用更新后的 current
                pageSize,
                isCheck,
                userId,
                b_orf: 'f'
            };

            // 调用 initData
            initData(ploay);
            // 在回调中更新 current，确保拿到最新的 prevState
            // 这里返回新的状态
            return prevState + 1;
        });


    };
    const showAllCheck = () => {
        const newShowList = [...tempShowList];  // 复制 showList
        setShowList(newShowList);
        if (newShowList.length > 0) {
            setCurrentProblomObj(newShowList[0]);  // 更新 showList 后设置第一个元素
        }

    }
    const showNoCheck = () => {
        const newShowList = showList.filter((item: any) => item.isCheck === '0');  // 筛选 isCheck 为 '0' 的元素
        setShowList(newShowList);
        if (newShowList.length > 0) {
            setCurrentProblomObj(newShowList[0]);  // 更新 showList 后设置第

        }
    }
    const changeAnswerBtn = (type: string) => {

        let currentIndex = findIndex(showList, currentId);
        if (type === 'before') {
            setCurrentId(showList[currentIndex - 1].id)
            setCurrentProblomObj((prevState: any) => {
                return {
                    ...prevState,
                    title: showList[currentIndex - 1].title,
                    answer: showList[currentIndex - 1].answer,
                    updateTime: showList[currentIndex - 1].updateTime,
                    isCheck: showList[currentIndex - 1].isCheck,
                    id: showList[currentIndex - 1].id,
                    type: showList[currentIndex - 1].type,
                    rate: showList[currentIndex - 1].rate,
                    userId: showList[currentIndex - 1].userId,
                }
            })
        } else {
            setCurrentId(showList[currentIndex + 1].id)
            setCurrentProblomObj((prevState: any) => {
                return {
                    ...prevState,
                    title: showList[currentIndex + 1].title,
                    answer: showList[currentIndex + 1].answer,
                    updateTime: showList[currentIndex + 1].updateTime,
                    isCheck: showList[currentIndex + 1].isCheck,
                    id: showList[currentIndex + 1].id,
                    type: showList[currentIndex + 1].type,
                    rate: showList[currentIndex + 1].rate,
                    userId: showList[currentIndex + 1].userId,
                }
            })
        }
    }
    const findIndex = (arr: any, id: string) => {
        let index = 0;
        for (let i = 0; i < showList.length; i++) {
            if (showList[i].id == currentId) {
                index = i;
            }
        }
        return index;

    }
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const onIsCheckChange = (e: any) => {
        console.log("@@@", e.target.checked);

        if (e.target.checked) {
            showNoCheck()
        } else {
            showAllCheck()
        }
    }
    const [updateObj, setUpdateObj] = useState({
        title: "",
        rate: '',
        type: '',
        answer: '',
        id: ''
    })
    const changeIsCheck = (item: any) => {
        console.log("isCheck", isCheck);

        setCurrentProblomObj((prevState: any) => {
            const updatedObj = {
                ...prevState,
                isCheck: prevState.isCheck === '1' ? '0' : '1',
            };
            // 同步更新 showList 中的对应项
            setShowList((prevList: any[]) =>
                prevList.map((entry) =>
                    entry.id === updatedObj.id
                        ? {...entry, isCheck: updatedObj.isCheck}
                        : entry
                )
            );

            // 调用接口时传递修改后的对象
            reqUpdateFaceClassic(updatedObj).then(res => {
                if (res.status) {
                    message.success("切换状态成功~");
                } else {
                    message.warning("切换状态失败~");
                }
            });

            return updatedObj;
        });
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openEdit = () => {

        setUpdateObj((prevState: any) => {
            return {
                ...prevState,
                title: currentProblomObj.title,
                type: currentProblomObj.type,
                rate: currentProblomObj.rate,
                id: currentProblomObj.id,
                answer: currentProblomObj.answer
            }
        })
        setIsModalOpen(true);
    };
    const handleOk = () => {
        console.log("修改的值是", updateObj)
        if (richTextRef.current) {
            const editorContent = richTextRef.current.getEditorContent();
            setUpdateObj((prevState: any) => ({
                ...prevState,
                answer: editorContent,
            }));
            const reqUpdatedObj = {
                ...updateObj,
                answer: editorContent,  // 使用最新的编辑器内容
            };
            reqUpdateFaceClassic(reqUpdatedObj).then(res => {
                if (res.status) {
                    message.success("修改成功~")
                    setCurrentProblomObj((prevState: any) => {
                        return {
                            ...prevState,
                            title: updateObj.title,
                            answer: editorContent,
                            type: updateObj.type,
                            rate: updateObj.rate,

                        }
                    })
                    // 更新 showList 中对应 id 的项
                    setShowList((prevShowList: any[]) =>
                        prevShowList.map((item) =>
                            item.id === reqUpdatedObj.id
                                ? { ...item, ...reqUpdatedObj }
                                : item
                        )
                    );

                    setIsModalOpen(false);
                } else {
                    message.warning("修改失败~")
                }
            })

        }


    };

    const closeModal = () => {
        setIsModalOpen(false);
    }
    const handleUTitle = (e: any) => {
        setUpdateObj((prevState: any) => {
            return {
                ...prevState,
                title: e.target.value,
            }
        })
    }
    const handleUType = (e: any) => {
        setUpdateObj((prevState: any) => {
            return {
                ...prevState,
                type: e
            }
        })
    }
    const handleURate = (e: any) => {
        setUpdateObj((prevState: any) => {
            return {
                ...prevState,
                rate: e,
            }
        })
    }
    const richTextRef: any = useRef<any>(null);
    const getEditorContent = () => {
        if (richTextRef.current) {
            const editorContent = richTextRef.current.getEditorContent();
            setUpdateObj((prevState: any) => ({
                ...prevState,
                answer: editorContent,
            }));
        }
    };
    const [showMore, setShowMore] = useState(false)
    const nav = useNavigate()
    const goHomePage=()=>{
        nav('/fMainPage/fHomePage')
    }
    const goBehind=()=>{
        if (userId==='4500fbd3-5e3c-407b-b158-51e1ed19ee3a'){
            nav('/admin')
        }else{
            nav('/ordinaryAdministrator')
        }

    }
    const calculateDelay = (index: number) => {
        const pageSize = 20;  // 每页的元素数
        const maxDelay = 2;   // 最大延迟，例如 2s
        const minDelay = 0.1; // 最小延迟，例如 0.1s
        const delayStep = (maxDelay - minDelay) / pageSize; // 每步的递增延迟

        // 从最小延迟递增到最大延迟
        const delay = minDelay + (index % pageSize) * delayStep;

        return Math.min(delay, maxDelay); // 确保延迟时间不会超过最大值
    };


    return (
        <div className={styles['mainBox']}>
            <div style={{display: 'none'}} className={styles['headerTop']}>
                <div>
                    {/*<Search className={styles['searchInput']} placeholder="暂未开放" onSearch={onSearch} enterButton/>*/}

                    {/*<Checkbox value={isCheck} onChange={onIsCheckChange}>未掌握</Checkbox>*/}


                </div>
                <div>
                    <div className={styles['settingIcon']}>
                        <SettingOutlined onClick={showDrawer}/>
                    </div>

                </div>
                <Drawer title="基础设置" closeIcon={false} onClose={onClose} open={open}>
                    <ul className={styles['settingUl']}>
                        <li className={styles['settingLi']}>
                            <div className={styles['configName']}>
                                布局
                            </div>
                            <div className={styles['boxStyleSettting']}>
                                <div>
                                    <LeftIcon className={styles['iconStyles']}
                                              onClick={() => configColnum(1)}></LeftIcon>一列
                                </div>
                                <div>
                                    <CenterIcon className={styles['iconStyles']}
                                                onClick={() => configColnum(2)}></CenterIcon>二列
                                </div>
                                <div>
                                    <RightIcon className={styles['iconStyles']}
                                               onClick={() => configColnum(3)}></RightIcon>三列
                                </div>

                            </div>
                        </li>
                        <li className={styles['settingLi']}>
                            <div className={styles['configName']}>
                                复习模式(暂未开放)
                            </div>
                            <div className={styles['boxStyleSettting']}>
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked/>


                            </div>
                        </li>
                    </ul>
                </Drawer>
            </div>
            <div className={styles['content']}>

                <div className={styles['mainContent']}>
                    <div style={{width: `${contentLWidth}%`}} className={styles['content-l']}>
                        <div
                            className={`${styles['radioSty']} ${showMore ? styles['radioBig'] : styles['radioSmall']}`}>
                            <Radio.Group
                                className={`${styles['myRadio']} ${showMore ? styles['radioBig'] : styles['radioSmall']}`}
                                buttonStyle="solid"
                                value={type}
                                onChange={onChange}
                                defaultValue="1"
                            >
                                {typeList.length > 0 && typeList.map((item: any) => (
                                    <Badge overflowCount={999} key={item.id} color="#87d068" count={item.num}>
                                        <Radio.Button value={item.id}>     {newTagContent[item.id]}{item.value}</Radio.Button>
                                    </Badge>
                                ))}
                            </Radio.Group>
                            <div onClick={() => setShowMore(!showMore)}
                                 className={`${styles['showMore']} ${showMore ? styles['showS'] : styles['showZ']}`}>
                                {showMore ? <div>
                                    收起 <DoubleRightOutlined style={{transform: 'rotate(270deg)'}}/>
                                </div> : <div>
                                    展开
                                    <DoubleRightOutlined style={{transform: 'rotate(90deg)'}}/>
                                </div>}
                            </div>
                        </div>

                        <div className={styles['outBox']}>
                            {loadingBtn ? <div className={styles['loadingStyle']}>
                                <BtnLoading data={35}></BtnLoading>
                            </div> : <ul style={{gridTemplateColumns: `repeat(${colnumNum}, 1fr)`}}
                                         className={styles['contentLUl']}>
                                {
                                    showList.map((item: any, index: number) => {
                                        return <li onClick={() => changeAnswer(item)}
                                                   className={`${styles['contentLLi']} ${currentId === item.id ? styles['activeLi'] : ''}`}
                                                   key={item.id}
                                                   style={{
                                                       animationDelay: `${calculateDelay(index)}s`
                                                   }}>
                                            {item.isVip=='1'&&
                                                <div className={styles['vipStyle']}>
                                                    <VipIcon></VipIcon>
                                                </div>
                                            }
                                            <div className={styles['liTop']}>
                                                <div className={styles['liLeftTop']}>
                                                    {index + 1}、
                                                    {item.title}
                                                </div>
                                            </div>
                                            <div className={styles['liBottom']}>
                                                <div></div>
                                                <div className={styles['liTag']}>
                                                    {TagContent[item.type]}
                                                </div>
                                                <div>

                                                    {RateBox(item.rate)}

                                                </div>
                                            </div>


                                        </li>
                                    })
                                }
                                {showLoadMoreBtn && <div className={styles['loadMore']}>

                                    <div className={styles['inLoading']} onClick={loadMoreBtn}>
                                        加载更多{loadingMore ? <LoadingOutlined style={{marginLeft: 3}}/> : ''}
                                    </div>
                                </div>}
                            </ul>

                            }

                        </div>
                    </div>
                    <div style={{width: `${100 - contentLWidth}%`}} className={styles['content-r']}>
                        {loadingBtn ? <div className={styles['loadingStyle']}>
                            <BtnLoading data={35}></BtnLoading>
                        </div> : <div style={{height: '100vh'}}>

                            <div className={styles['rMainContent']}>
                                <div className={styles['answerTop']}>
                                    <QuestionIcon style={{marginRight: 3}}></QuestionIcon>
                                    <div className={styles['answerTopTitle']}> {currentProblomObj.title}</div>
                                </div>
                                {showList.length > 0 &&
                                    <AnswerItem data={currentProblomObj}/>
                                }
                            </div>

                            {showList.length > 0 &&
                                <div className={styles['contentRBottom']}>
                                    <div className={styles['tipL']}>
                                        <div className={styles['bottomL']}>
                                            {/*当前状态：*/}
                                            {/*{*/}
                                            {/*    currentProblomObj.isCheck === '1' ? <Tag color="blue"> 已掌握</Tag> :*/}
                                            {/*        <Tag color="error"> 未掌握</Tag>*/}
                                            {/*}*/}
                                            {/*<SwitchIcon className={styles['swicthStyle']}*/}
                                            {/*            onClick={() => changeIsCheck(currentProblomObj)}/>*/}


                                        </div>
                                        <div className={styles['tip']}>
                                            <div className={styles['editOutBox']} onClick={openEdit}><FormOutlined
                                                className={styles['editBtn']} />编辑
                                            </div>
                                   <span style={{display:'flex'}}>
                                                上次更新： <div className={styles['answerTime']}>
                                            {currentProblomObj.updateTime}

                                       {/*<Popover placement="top" title={null} content="编辑">*/}

                                       {/*</Popover>*/}


                                        </div>
                                   </span>

                                        </div>

                                        <Modal centered width={1200} className={styles['modalStyles']} title="修改"
                                               open={isModalOpen} onOk={handleOk} onCancel={closeModal}>
                                            <Form

                                                name="basic"
                                                labelCol={{span: 4}}
                                                wrapperCol={{span: 16}}
                                                initialValues={{remember: true}}
                                                autoComplete="off"
                                            >
                                                <Form.Item<any>
                                                    label="问题名称"
                                                >
                                                    <Input value={updateObj.title} onChange={handleUTitle}/>
                                                </Form.Item>

                                                <Form.Item<any>
                                                    label="类型"

                                                >
                                                    <Select
                                                        defaultValue={currentProblomObj.type}
                                                        style={{width: 120}}
                                                        value={updateObj.type}
                                                        onChange={handleUType}
                                                        options={selectTypeList}
                                                    />
                                                </Form.Item>

                                                <Form.Item<any>
                                                    label="频率"
                                                    rules={[{required: true, message: 'Please input your password!'}]}
                                                >
                                                    <Select
                                                        defaultValue={currentProblomObj.rate}
                                                        style={{width: 120}}
                                                        value={updateObj.rate}
                                                        onChange={handleURate}
                                                        options={[
                                                            {value: '1', label: '低'},
                                                            {value: '2', label: '中'},
                                                            {value: '3', label: '高'},
                                                        ]}
                                                    />
                                                </Form.Item>
                                                <Form.Item<any>
                                                    label="答案解析"
                                                >
                                                    {
                                                        updateObj.answer === currentProblomObj.answer &&
                                                        <RichText data={updateObj.answer} ref={richTextRef}/>
                                                    }

                                                </Form.Item>


                                            </Form>
                                        </Modal>
                                    </div>
                                    <div>
                                        <Button type="link" onClick={goHomePage}>去首页</Button>
                                        <Button type="link" onClick={goBehind}>去后台</Button>
                                    </div>
                                    <div className={styles['pageStyles']}>
                                        {findIndex(showList, currentId) + 1}/{showList.length}
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Button onClick={() => changeAnswerBtn('before')}
                                                disabled={showList[0].id === currentId} type="primary"
                                                icon={<LeftOutlined/>} className={styles['beforeBtn']}>上一题</Button>

                                        <Button onClick={() => changeAnswerBtn('last')}
                                                disabled={showList[showList.length - 1].id === currentId} type="primary"
                                                iconPosition="end" icon={<RightOutlined/>}
                                                className={styles['lastBtn']}>下一题</Button>
                                    </div>
                                </div>
                            }
                        </div>
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}
export default FaceClassic